<template>
    <div class="d-flex flex-column border border-2 rounded-3 px-2 py-2" style="height:100%">
        <div class="d-flex py-1 align-items-baseline">
            <h4 class="me-2">{{dashboard.name}}</h4>
            <a class="btn btn-primary py-0 me-2" :href="dashboardLink" target="_blank">
                <i class="fas fa-external-link-alt"></i>
            </a>
            <button 
                class="btn btn-secondary py-0" 
                @click="copyCode"
                data-bs-toggle="popover"
                data-bs-trigger="click"
                data-bs-content="Link copiado!"
                ref="copyBtn">
                <i class="far fa-clone"></i>
            </button>
        </div>
        <p class="fs-6 m-0"><i class="fas fa-building"></i> {{dashboard.company}}</p>
        <div class="mb-2 fs-6 d-flex flex-grow-1">
            <p class="me-3"><i class="fas fa-th"></i> {{dashboard.qtd_resources}}</p>
            <p><i class="fas fa-eye"></i> {{dashboard.connectedClients}}</p>
        </div>
        <div>
            <p class="fs-6 m-0">Criado em {{createdAt}} por {{dashboard.created_by}}</p>
            <input class="form-control" type="text" readonly="true" :value="dashboardLink" ref="linkInput"/>
        </div>
    </div>
</template>

<script>
import {Popover} from 'bootstrap'
import config from '../../config'

export default {
    name: 'AdminRootDashboardItem',
    data() {
        return {
            copyBtnPopover: null,
            copyBtnPopoverTimer: null,
        }
    },
    props:{
        dashboard: {
            type: Object
        },
    },
    computed: {
        createdAt() {
            return this.dashboard.creation.toLocaleString()
        },
        dashboardLink() {
            return `${config.baseUrl}/${this.dashboard.code}`
        }
    },
    methods: {
        copyCode() {
            window.clearTimeout(this.copyBtnPopoverTimer)
            this.$refs.linkInput.select()
            document.execCommand('copy')
            this.copyBtnPopoverTimer = window.setTimeout(() => this.copyBtnPopover.hide(), 2000)
        }
    },
    mounted() {
        this.copyBtnPopover = new Popover(this.$refs.copyBtn)
    },
    unmounted() {
        if (this.copyBtnPopover)
            this.copyBtnPopover.dispose()
    }
}
</script>

<style scoped>
.small-text{
    font-size:0.85em;
    margin:2px 0px;
}
.clickable {
    cursor: pointer
}
</style>