<template>
    <nav 
        class="container-fluid bg-primary py-2 d-flex justify-content-between align-items-baseline top-0"
        :class="{'position-fixed': scrollY >= 100}"
        style="z-index:9999">
        <horizontal-link :white="true"></horizontal-link>
        <button class="btn btn-light" @click="logout">Sair</button>
    </nav>
    <div class="container-fluid">
        <!-- statistics -->
        <div>
            <div class="d-flex align-items-baseline mt-1">
                <button 
                    class="btn btn-primary me-1 px-2 py-0"
                    @click="updateStatistics">
                    <small><i class="fas fa-sync"></i></small>
                </button>
                <h4>Statistics</h4>
            </div>
            <div class="row">
                <ul class="ul-side">
                    <li><strong>Connected clients:</strong> {{statistics?.connected_clients || 0}}</li>
                    <li><strong>Active dashboards count:</strong> {{statistics?.current_active_dashboards || 0}}</li>
                </ul>
            </div>
        </div>

        <!-- Dashboards list -->
        <div>
            <div class="d-flex align-items-center justify-content-between mt-3 mb-2">
                <div class="d-flex align-items-baseline">
                    <button 
                        class="btn btn-primary me-1 px-2 py-0"
                        @click="updatedashboards">
                        <small><i class="fas fa-sync"></i></small>
                    </button>
                    <h4>Dashboards</h4>
                </div>
                <!-- Barra de pesquisa -->
                <div class="input-group mx-3">
                    <span class="input-group-text"><i class="fa fa-search"></i></span>
                    <input type="text" maxlength="20" class="form-control" placeholder="Código, nome, empresa ou criador" @keyup="findDashboard"/>
                </div>
                <!-- Ordenação -->
                <div class="dropdown dropstart">
                    <button class="btn btn-secondary" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-sort"></i>
                    </button>
                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="#" @click="sortdashboards(0)">
                            Nenhum ordenamento&nbsp;
                            <i class="fas fa-check" v-show="orderCode === 0"></i>
                        </a></li>
                        <li><a class="dropdown-item" href="#" @click="sortdashboards(1)">
                            Recursos&nbsp;
                            <i v-show="orderCode === 1" class="fas" :class="{'fa-sort-down': !sortAsc, 'fa-sort-up': sortAsc}"></i>
                        </a></li>
                        <li><a class="dropdown-item" href="#" @click="sortdashboards(2)">
                            Clientes conectados&nbsp;
                            <i v-show="orderCode === 2" class="fas" :class="{'fa-sort-down': !sortAsc, 'fa-sort-up': sortAsc}"></i>
                        </a></li>
                        <li><a class="dropdown-item" href="#" @click="sortdashboards(3)">
                            Data criação&nbsp;
                            <i v-show="orderCode === 3" class="fas" :class="{'fa-sort-down': !sortAsc, 'fa-sort-up': sortAsc}"></i>
                        </a></li>
                    </ul>
                </div>
            </div>
            <!-- Paginação da lista de dashboards -->
            <div class="d-flex justify-content-center align-items-center mb-2">
                <button class="btn btn-secondary" @click="navigatePage(-1)">
                    <i class="fas fa-angle-left"></i>
                </button>
                <span class="mx-2">Página {{currPage+1}}/{{maxPages}}</span>
                <button class="btn btn-secondary" @click="navigatePage(1)">
                    <i class="fas fa-angle-right"></i>
                </button>
            </div>
            <!-- Lista de dashboards -->
            <div class="row gy-3">
                <div 
                    v-for="dashboard in dashboardList" :key="dashboard.code"
                    class="col-12 col-md-6 col-lg-4 col-xxl-3">
                    <AdminRootDashboardItem :dashboard="dashboard"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../config'
import HorizontalLink from '../components/HorizontalLink.vue'
import AdminRootDashboardItem from '../components/AdminRoot/AdminRootDashboardItem.vue'

const MAX_ITEMS_PER_PAGE = 9

export default {
    name:'AdminRoot',
    components: {HorizontalLink, AdminRootDashboardItem}, 
    data() {
        return {
            token: '',
            statistics: null,
            dashboardShowList: [],
            dashboards: null,
            orderCode: 0,
            sortAsc: false,
            searchDashboardTimer: null,
            scrollY: 0,
            currPage: 0
        }
    },

    computed: {
        maxPages() {
            return Math.ceil((this.dashboardShowList?.length || 0) / MAX_ITEMS_PER_PAGE)
        },
        dashboardList() {
            const idx = this.currPage*MAX_ITEMS_PER_PAGE;
            return this.dashboardShowList.slice(idx, MAX_ITEMS_PER_PAGE+idx)
        }
    },

    watch: {
        maxPages() {
            if (this.currPage >= this.maxPages)
                this.currPage = this.maxPages-1
        }
    },

    methods: {
        navigatePage(offset) {
            if (this.currPage + offset > this.maxPages-1)
                return
            if (this.currPage + offset < 0)
                return
            this.currPage += offset
        },
        logout() {
            window.localStorage.setItem('admin-token', '')
            this.$router.replace('/admin')
        },
        onWindowScroll() {
            this.scrollY = window.scrollY
        },

        findDashboard(evt) {
            window.clearTimeout(this.searchDashboardTimer)
            this.searchDashboardTimer = window.setTimeout(() => {
                const term = evt.target.value.trim().substr(0, 20)
                if (!term) {
                    this.dashboardShowList = this.dashboards
                    return
                }
                const rgxp = RegExp(term, 'i')

                this.dashboardShowList = this.dashboards.filter(d => 
                    d.code.match(rgxp) || d.name.match(rgxp) || d.company.match(rgxp) || d.created_by.match(rgxp)
                )
            }, 500)
        },
        sortdashboards(code) {
            if (this.orderCode === code)
                this.sortAsc = !this.sortAsc
            else
                this.sortAsc = true
            this.orderCode = code

            let list
            if (this.orderCode)
                list = [...this.dashboards]
            switch (this.orderCode) {
                case 1: 
                    list.sort((a, b) => (a.qtd_resources - b.qtd_resources))
                    break

                case 2:
                    list.sort((a, b) => (a.connectedClients - b.connectedClients))
                    break
                
                case 3:
                    list.sort((a, b) => (a.creation - b.creation))
                    break

                default: list = this.dashboards
            }

            if (this.orderCode && !this.sortAsc)
                list.reverse()

            this.dashboardShowList = list
        },
        validateResponseStatus(response) {
            if (response.status !== 200)
                throw 'Remote error: '+response.status
        },
        async updateStatistics() {
            const response = 
                await fetch(config.createLink('/admin/backend/statistics'), {method: 'GET', headers: {'Authorization': this.token}})

            this.validateResponseStatus(response)

            this.statistics = await response.json()
            this.associateConnectedClients()
        },
        async updatedashboards() {
            this.dashboards = []

            const response = await fetch(config.createLink('/admin/dashboards'), {method: 'GET', headers: {'Authorization': this.token}})

            this.validateResponseStatus(response)

            this.dashboards = (await response.json()).map(e => {
                e.connectedClients = 0
                e.creation = new Date(e.creation)
                return e
            })
            this.associateConnectedClients()
            this.dashboardShowList = this.dashboards
        },
        associateConnectedClients() {
            const connClients = this.statistics?.connected_clients_per_dashboard
            if (!connClients)
                return

            this.dashboards.forEach(dashboard => {
                const connClientEntry = connClients.find(e => e.code === dashboard.code)
                dashboard.connectedClients = connClientEntry ? connClientEntry.count : 0
            })
        },
    },

    created() {
        this.token = window.localStorage.getItem('admin-token')
    },

    mounted() {
        Promise.all([this.updatedashboards(), this.updateStatistics()])
            .then(() => {
             //   console.log('All data loaded')
                window.addEventListener('scroll', this.onWindowScroll)
            })
            .catch(e => {
                console.error('Remote error: '+e)
                window.localStorage.setItem('admin-token', '')
                window.location.href = "/admin"
            })
    },
    unmounted() {
        window.removeEventListener('scroll', this.onWindowScroll)
    }
}
</script>

<style scoped>
.ul-side{list-style: none} .ul-side li {float:left; margin-right:1em}
</style>